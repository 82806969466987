import React, { useState, useEffect } from 'react';
import styles from './style.module.scss';

const ScrollToTopButton: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 700) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      {isVisible && (
        <button className={styles.scrollButton} onClick={scrollToTop}>
          <svg
            className="backtotop-icon"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 20.25V3.75"
              stroke="#515151"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M5.25 10.5L12 3.75L18.75 10.5"
              stroke="#515151"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
          <span className={styles.backtotoptext}>Top</span>
        </button>
      )}
    </>
  );
};

export default ScrollToTopButton;
